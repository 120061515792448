<template>
  <div class="field-row health-plan-select">
    <div class="flex-fill">
      <multiselect
        class="form-control"
        ref="health_plan_id"
        v-model="value.health_plan_id"
        :options="healthPlans"
        track-by="id"
        label="fantasy_name"
        :showLabels="false"
        :allowEmpty="false"
        placeholder="Filtrar por Convênio..."
      >
        <template #caret>
          <div class="chevron" :class="{'moved-chevron': visibleIcon}">
            <ChevronDown />
          </div>
          <div class="close clear" @click.prevent="clear" v-if="visibleIcon">
            <Close />
          </div>
        </template>
        <template #singleLabel="props">
          <span class="option__title">{{ props.option.fantasy_name }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  name: "BudgetStatusSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  props: {
    value: Object,
    clinic_id: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      healthPlans: [],
    }
  },

  mounted() {
    this.getClinicHealthPlans()
  },

  computed: {
    visibleIcon() {
      return this.value.health_plan_id
    }
  },

  methods: {
    getClinicHealthPlans() {
      this.api.getAllClinicHealthPlans(this.clinic_id).then(res => {
        res.data.map(healthPlan => {
          this.healthPlans.push({
            id: healthPlan.id,
            fantasy_name: healthPlan.health_plan.fantasy_name,
            clinic_health_plan: healthPlan
          })
        })
      })
    },

    clear() {
      console.log('clearing everything')
      this.value.health_plan_id = null
    },
  }
}
</script>

<style lang="scss">
.request-types-combo.form-control {
  padding: 0;
}
</style>
<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }
.health-plan-select {
  .clear {
    width: 18px;
    height: 28px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    position: absolute;
    transition: all 500ms;
  }

  .moved-chevron {
    right: 32px;
  }
}
</style>